var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"font-weight-medium text-h6 mb-3"},[_vm._v(" "+_vm._s(_vm.$i18n.t("forms.model.title"))+" ")]),_c('v-card',{staticClass:"rounded-md mb-4"},[_c('div',{staticClass:"d-flex align-center justify-start px-4 py-3 flex-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","loading":_vm.loading.refresh},on:{"click":function($event){return _vm.refreshItems()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$i18n.t("btn.refresh"))+" ")],1),_c('div',{staticClass:"ml-auto"},[(_vm.$permission.$can('model-field.create', ['user', 'service']))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addModelData()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.$i18n.t("btn.add")))])],1):_vm._e()],1)],1)]),_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading.list,"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"footer-props":_vm.footerProps,"items-per-page":_vm.itemPerPage,"sort-by":"id","sort-desc":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm._.isEmpty(_vm.items))?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('v-hover',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',[_c('td',{staticStyle:{"min-width":"160px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"))+" ")])]),_c('td',[_vm._v(_vm._s(item.identifier))]),_c('td',[_vm._v(_vm._s(item.service.name))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',{staticStyle:{"min-width":"120px"}},[(
                      _vm.$permission.$can('model-field.update', [
                        'user',
                        'service' ])
                    )?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.editModelData(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("forms.model.btn.edit")))])])]:_vm._e(),(
                      _vm.$permission.$can('model-field.delete', [
                        'user',
                        'service' ])
                    )?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover || _vm.$vuetify.breakpoint.mdAndDown),expression:"hover || $vuetify.breakpoint.mdAndDown"}],staticClass:"ml-2",attrs:{"x-small":"","icon":"","loading":_vm.loading.delete && _vm.model.id === item.id},on:{"click":function($event){return _vm.deleteModelData(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("forms.model.btn.delete")))])])]:_vm._e()],2)])]}}],null,true)})]})],2)]}}:null],null,true)})],1),_c('form-model-data',{attrs:{"dialog":_vm.dialog.form.display,"services":_vm.services,"form":_vm.form,"title":_vm.dialog.form.title},on:{"success":_vm.refreshItems,"update:dialog":function($event){return _vm.$set(_vm.dialog.form, "display", $event)},"update:form":function($event){_vm.form=$event}}}),_c('confirm-delete-model',{attrs:{"dialog":_vm.dialog.delete},on:{"confirm":_vm.confirmDeleteModel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }