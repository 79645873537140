<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">
      {{ $i18n.t("forms.model.title") }}
    </div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $i18n.t("btn.refresh") }}
        </v-btn>
        <div class="ml-auto">
          <v-btn
            color="primary"
            @click="addModelData()"
            v-if="$permission.$can('model-field.create', ['user', 'service'])"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>
            <span>{{ $i18n.t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px">
                    <span>
                      {{
                        $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
                      }}
                    </span>
                  </td>
                  <td>{{ item.identifier }}</td>
                  <td>{{ item.service.name }}</td>
                  <td>{{ item.description }}</td>
                  <td style="min-width: 120px">
                    <template
                      v-if="
                        $permission.$can('model-field.update', [
                          'user',
                          'service',
                        ])
                      "
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="editModelData(item)"
                          >
                            <v-icon color="primary">mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("forms.model.btn.edit") }}</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-if="
                        $permission.$can('model-field.delete', [
                          'user',
                          'service',
                        ])
                      "
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteModelData(item)"
                            class="ml-2"
                            :loading="loading.delete && model.id === item.id"
                          >
                            <v-icon color="primary">mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("forms.model.btn.delete") }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <form-model-data
      @success="refreshItems"
      :dialog.sync="dialog.form.display"
      :services="services"
      :form.sync="form"
      :title="dialog.form.title"
    ></form-model-data>
    <confirm-delete-model
      :dialog="dialog.delete"
      @confirm="confirmDeleteModel"
    ></confirm-delete-model>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { defaultFormModelData } from "./../../../constants";
import FormModelData from "./form.vue";
import ListMixin from "./../../../mixins/list";
import ConfirmDeleteModel from "./../../commons/confirm.vue";

export default {
  mixins: [ListMixin],
  created() {
    this.setServicesItems();
  },
  data: () => ({
    path: "models-fields.list",
    dialog: {
      form: {
        display: false,
        title: null,
      },
      delete: {
        display: false,
        message: null,
      },
    },
    form: { ...defaultFormModelData },
    model: {},
    services: [],
    loading: {
      delete: false,
    },
  }),
  methods: {
    addModelData() {
      this.form = { ...defaultFormModelData };
      this.dialog.form.display = true;
      this.dialog.form.title = this.$t("forms.model.add.title");
    },
    editModelData(item) {
      this.form.id = item.id;
      this.form.description = item.description;
      this.form.identifier = item.identifier;
      this.form.serviceId = item.serviceId;

      this.dialog.form.title = this.$t("forms.model.edit.title");
      this.dialog.form.display = true;
    },
    deleteModelData(item) {
      this.model = item;
      this.dialog.delete.message = this.$t("forms.model.delete.confirm");
      this.dialog.delete.display = true;
    },
    async confirmDeleteModel() {
      try {
        this.loading.delete = true;
        await this.request({
          url: "models-fields.delete",
          method: "POST",
          data: {
            modelFieldId: this.model.id,
          },
          messages: {
            204: this.$t("forms.model.delete.success"),
            403: true,
            500: true,
            422: this.$t("error_codes.500"),
          },
        });
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    },
    setHeaders() {
      this.headers = [
        {
          text: this.$i18n.t("common.fields.created.title"),
          align: "start",
          sortable: true,
          value: "createdAt",
          width: 160,
        },
        {
          text: this.$i18n.t("common.fields.identifier.title"),
          align: "start",
          sortable: true,
          value: "identifier",
          width: 120,
        },
        {
          text: this.$i18n.t("common.fields.serviceId.title"),
          align: "start",
          sortable: true,
          value: "service.name",
          width: 120,
        },
        {
          text: this.$i18n.t("common.fields.description.title"),
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: null,
          width: 120,
        },
      ];
    },
    async setServicesItems() {
      try {
        const response = await this.request({ url: "services.items" });
        this.services = response.data;
      } catch (error) {
        // empty
      }
    },
    ...mapActions({ request: "request" }),
  },
  components: {
    FormModelData,
    ConfirmDeleteModel,
  },
};
</script>
