<template>
  <div>
    <forms-model-data></forms-model-data>
  </div>
</template>

<script>
import FormsModelData from "./../../../components/forms/model/list.vue";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t("forms.model.title"),
    };
  },
  components: {
    FormsModelData,
  },
};
</script>
