<template>
  <v-dialog v-model="dialogValue" max-width="500" persistent>
    <v-card>
      <v-card-title>
        <span class="secondary-font font-weight-medium text-h6">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text class="px-7 mt-4">
        <v-form ref="form" v-model="isValid">
          <v-select
            :items="services"
            item-value="id"
            item-text="name"
            class="mb-3"
            v-model="formValue.serviceId"
            append-icon="mdi-domain"
            :label="$t('common.fields.serviceId.title')"
            dense
            :rules="serviceRules"
            :disabled="isEdit"
          ></v-select>
          <v-text-field
            v-model="formValue.identifier"
            append-icon="mdi-identifier"
            :label="$t('common.fields.identifier.title')"
            dense
            class="mb-3"
            :rules="identifierRules"
            :disabled="isEdit"
          ></v-text-field>
          <v-file-input
            v-model="formValue.file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :label="$t('forms.model.fields.file.title')"
            dense
            show-size
            prepend-icon=""
            append-icon="mdi-file-excel-outline"
            class="mb-3"
            :rules="fileRules"
          ></v-file-input>
          <v-textarea
            dense
            rows="2"
            no-resize
            v-model="formValue.description"
            :label="$t('common.fields.description.title')"
            append-icon="mdi-text"
            :rules="descriptionRules"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">{{ $t("btn.close") }}</v-btn>
        <v-btn
          :disabled="!isValid"
          :loading="loading"
          text
          @click="save()"
          color="primary"
        >
          <span class="font-weight-medium">
            <span v-if="!isEdit">{{ $t("btn.save") }}</span>
            <span v-else>{{ $t("btn.update") }}</span>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    dialog: Boolean,
    form: Object,
    services: Array,
    title: String,
  },
  data: () => ({
    loading: false,
    isValid: false,
  }),
  methods: {
    close() {
      this.dialogValue = false;
    },
    async save() {
      this.notify({ status: false });
      if (!this.$refs.form.validate()) {
        this.notify({ message: this.$t("error_codes.400") });
        return;
      }

      this.loading = true;

      const formData = new FormData();

      formData.append("identifier", this.formValue.identifier);
      formData.append("serviceId", this.formValue.serviceId);
      formData.append("file", this.formValue.file, this.formValue.file.name);

      if (this.formValue.description) {
        formData.append("description", this.formValue.description);
      }

      let url;
      if (this.isEdit) {
        url = "models-fields.update";
        formData.append("modelFieldId", this.formValue.id);
      } else {
        url = "models-fields.create";
      }

      try {
        await this.request({
          url: url,
          data: formData,
          method: "POST",
          messages: {
            201: this.$t("forms.model.add.success"),
            200: this.$t("forms.model.edit.success"),
            403: true,
            400: true,
            404: this.$t("forms.model.errors.404"),
            500: true,
            422: (error) => {
              if (error.code === "E100") {
                return this.$t("forms.errors.E100");
              } else if (error.code === "E101") {
                return this.$t("error_codes.400");
              } else if (error.code === "E102") {
                return this.$t("common.fields.identifier.exists");
              } else if (error.code === "E103") {
                return this.$t("forms.model.fields.file.required");
              }
              return this.$t("error_codes.500");
            },
          },
        });
        this.resetForm();
        this.$emit("success");
        this.dialogValue = false;
      } catch (error) {
        // empty
      }
      this.loading = false;
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
  },
  computed: {
    dialogValue: {
      get: function() {
        return this.dialog;
      },
      set: function(value) {
        return this.$emit("update:dialog", value);
      },
    },
    formValue: {
      get: function() {
        return this.form;
      },
      set: function(value) {
        return this.$emit("update:form", value);
      },
    },
    serviceRules() {
      return [
        (value) => {
          if (value == null || value === "")
            return this.$t("common.fields.serviceId.required");
          return true;
        },
      ];
    },
    identifierRules() {
      return [
        (value) => {
          if (value == null || value === "")
            return this.$t("common.fields.serviceId.required");
          if (!/^[a-z0-9_-]{1,50}$/i.test(value))
            return this.$t("common.fields.identifier.invalid");
          return true;
        },
      ];
    },
    fileRules() {
      return [
        (value) => {
          const size = 5000000;
          if (value == null || value === "")
            return this.$t("forms.model.fields.file.required");
          if (value.size > size)
            return this.$t("forms.model.fields.file.invalid", { size: size });
          return true;
        },
      ];
    },
    descriptionRules() {
      return [
        (value) => {
          if (value == null || value === "")
            return this.$t("common.fields.description.required");
          if (value.length > 255)
            return this.$t("common.fields.description.max_length", {
              max: 255,
            });
          return true;
        },
      ];
    },
    isEdit() {
      return this.formValue.id ? true : false;
    },
  },
};
</script>
